export default [
    {
        path: '/city-arrivals',
        name: 'city-arrivals',
        component: () => import('@/views/city-arrivals/Main.vue'),
        meta: {
            auth: ['super-admin', 'admin', 'manager', 'content-maker'],
        },
    },
    {
        path: '/city-arrivals/create',
        name: 'city-arrivals-create',
        component: () => import('@/views/city-arrivals/Create.vue'),
        meta: {
            auth: ['super-admin', 'admin', 'manager', 'content-maker'],
        },
    },
    {
        path: '/city-arrivals/:id/edit',
        name: 'city-arrivals-edit',
        component: () => import('@/views/city-arrivals/Edit.vue'),
        meta: {
            auth: ['super-admin', 'admin', 'manager', 'content-maker'],
        },
    },
];
